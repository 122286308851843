<template>
  <b-row class="new-patient" align-h="center">
    <b-card no-body class="col-md-6">
      <div class="form-content">
        <div class="header">
          <feather-icon icon="UserPlusIcon" class="text-primary" size="50" />

          <span>New patient</span>
        </div>
        <div class="sub-comment">
          <p class="text-muted">&nbsp;Add a new patient to the database</p>
        </div>

        <validation-observer ref="patientRules">
          <b-form @submit.prevent>
            <b-row>
              <!-- patient id -->
              <b-col cols="6">
                <b-form-group label="Patient ID" label-for="v-patient-id">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-patient-id"
                      placeholder="Patient ID"
                      v-model="patient.patient_id"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- hospital id -->
              <b-col cols="6">
                <b-form-group label="Hospital ID" label-for="v-hospital-id">
                  <validation-provider
                    #default="{ errors }"
                    name="hospital ID"
                    rules="required"
                  >
                    <v-select
                      v-model="hospital"
                      label="hospital_id"
                      :options="hospitals"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- date of birth -->
              <b-col cols="6">
                <b-form-group label="Date of birth" label-for="v-birth">
                  <b-input-group class="mb-1">
                    <b-form-input
                      id="example-input"
                      v-model="patient.date_of_birth"
                      type="text"
                      placeholder="DD.MM.YYYY"
                      v-mask="dateMask"
                      autocomplete="off"
                      show-decade-nav
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="date"
                        value-as-date
                        show-decade-nav
                        button-only
                        button-variant="outline-primary"
                        right
                        size="sm"
                        locale="de"
                        aria-controls="example-input"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 create-button"
                  @click="handleCreate"
                >
                  Create
                </b-button>
              </b-col>
              <b-col
                cols="6"
                v-if="is_empty_date"
                class="validation-date-string"
              >
                <small class="text-danger">The date of field is required</small>
              </b-col>

              <b-col cols="12">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  v-model="is_open_after_creation"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  Open patient after creation?
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BForm,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { convertDate2Str } from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BCard,
    BRow,
    BForm,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      value: "",
      date: null,
      hospital: null,
      hospitals: [],
      patient: {
        patient_id: "",
        hospital_id: null,
        date_of_birth: "",
      },
      required,
      is_empty_date: false,
      is_open_after_creation: true,
    };
  },
  watch: {
    date(v, ov) {
      if (v) {
        this.patient.date_of_birth = convertDate2Str(v);
      }
    },
    hospital(v, ov) {
      if (v) {
        this.patient.hospital_id = this.hospital._id;
      } else {
        this.patient.hospital_id = null;
      }
    },
  },
  mounted() {
    this.fetchHospitals();
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },

    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_create")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.expired) {
            this.logout();
            return;
          }

          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleCreate() {
      this.$refs.patientRules.validate().then((success) => {
        if (this.patient.date_of_birth < 10) {
          this.is_empty_date = true;
        } else {
          this.is_empty_date = false;
        }

        if (success && !this.is_empty_date) {
          this.$http
            .post("/patients/create", this.patient)
            .then((res) => {
              this.showToast("success", "Success", res.data.message);
              if (this.is_open_after_creation) {
                this.$router.push({
                  name: "patient-info",
                  params: { id: res.data.id },
                });
              } else {
                this.clearInput();
                this.$emit("fectch-patient");
              }
            })
            .catch((err) => {
              this.showToast("danger", "Error", err.response.data.message);
            });
        }
      });
    },
    clearInput() {
      this.patient.patient_id = "";
      this.patient.hospital_id = null;
      this.patient.date_of_birth = "";
    },
    dateMask(v) {
      let numbers = v.replace(/[^0-9]/g, "");
      let day = numbers.substring(0, 2),
        month = numbers.substring(2, 4),
        year = numbers.substring(4, 8);
      let isLeapYear = (y) =>
        y != 0 && y % 4 == 0 && (y % 100 != 0 || y % 400 == 0);
      let days = [
        0,
        31,
        28 + isLeapYear(+year),
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];

      if (day.length) {
        if (month.length == 2 && +month < 13) {
          if (+day > days[+month]) {
            day = "" + days[+month];
          }
        } else {
          if (+day > 31) {
            day = "31";
          }
        }

        if (day.length == 2 && +day == 0) {
          day = "01";
        }
      }

      if (month.length) {
        if (month.length == 2 && +month == 0) {
          month = "01";
        }
        if (+month > 12) {
          month = "12";
        }
      }

      let result = [];
      result.push(...day);
      if (day.length == 2) {
        result.push(".");
      }
      if (month.length) {
        result.push(...month);
        if (month.length == 2) {
          result.push(".");
        }
      }
      if (year.length) {
        result.push(...year);
      }

      return result;
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>
<style lang="scss">
</style>